/* COLORS */
:root {
  --main: #E3FF05;
  --secondary: lightcoral;
}

#aboutus {
  color: white;
}

.aboutuspic {
  width: 100%;
  max-height: 315px;
  margin: 50px auto 30px auto;
  border-radius: 180px;
  border: 4px solid black;
  box-shadow: 1px 1px 9px black;
  background-color: black;
}

#biography.container {
  background-color: rgba(0,0,0, 0.6);
  padding: 13px 13px 33px;
  max-width: 100%;
  margin: 0px;
}

.bio .yesdevil {
  color: var(--main);
  text-shadow: 2px 1px black;
}

.bio p {
  text-indent: 13%;
  margin: 50px 20px;
  text-align: center;
  text-shadow: 2px 1.5px black;
  max-width: 800px;
  letter-spacing: 3px;
}

.bio a {
  color: var(--main);
}

/* #aboutus p span {
  font-size: 27px;
} */

.members .row {
  padding-top: 27px;
}

.members .bioPic img {
  max-width: 333px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 1px 1px 9px black;
}

.members h4 {
  text-align: center;
  color: var(--main);
  text-shadow: 2px 1px black;
}
.members p {
  margin: auto;
  text-align: center;
}

.a2a_kit {
  margin: auto;
}

span.a2a_svg {
  height: 44px;
  width: 44px;
}

.followme {
  height: 77px;
  width: 200px;
  margin: auto;
  cursor: pointer;
}

.ind-bio {
  height: 227px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-width: 615px;
}

.ind-bio hr {
  margin-bottom: 15px;
}

.ind-bio a {
  margin: auto;
}

.bioPic {
  display: flex;
  justify-content: center;
}

.instafollow {
  width: 144px;
}

@media (max-width: 900px) {
  .members h4 {
    font-size: 2vw;
  }
}