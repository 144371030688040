/* COLORS */
:root {
  --main: #E3FF05;
  --secondary: rgb(240, 128, 128);
}

#contact {
  padding: 17px;
}

#contact .jumbotron {
  margin-top: 33px;
  margin-bottom: 2rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.3rem;
  padding: 4rem 2rem;
  border: 4px solid rgba(240, 128, 128, 0.3);
}

@media only screen and (max-width: 576px) {
  #contact .jumbotron {
    padding: 2rem 1rem;
  }
}

@media (min-width: 576px) {
  #contact .p-sm-5 {
      padding: 88px 44px;
  }
}

#contact h5 {
  color: var(--main);
  text-shadow: 2px 1px black;
}

@media (min-width: 576px) {
  #contact .p-sm-5 {
      padding: 88px 44px;
  }
}

#contact a {
  color: var(--main);
  text-shadow: 2px 1px black;
}

#contact p {
  letter-spacing: 3px;
  font-size: 1.4vw;
}

#contact a:hover {
  text-decoration: underline;
}

#contact hr {
  background-color: white;
}

.socmed.contact img {
  width: 55px;
  margin-right: 5px;
  margin-bottom: 13px;
  border-radius: 13px;
  background-color: rgba(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.socmed.contact img:hover {
  background-color: var(--main);
}

#contact .sendmsg {
  margin: auto;
  padding-bottom: 33px;
}

#contact .form-group {
  color: var(--main);
  text-shadow: 1px 1px black;
  margin-bottom: 1rem;
}

#contact label {
  margin-bottom: 0.5rem;
}

#contact .form-control {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

#contact .form-control:focus {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}

#contact svg {
  margin-right: 20px;
}

@media (max-width: 991px) {
  #contact p {
    font-size: 2vw;
  }
}

@media (max-width: 800px) {
  #contact p {
    font-size: 2.5vw;
  }
}

@media (max-width: 800px) {
  #contact p {
    font-size: 3vw;
  }
}

@media (max-width: 777px) {
  #contact p {
    font-size: 2.5vw;
  }
  #contact h5 {
    font-size: 3.5vw;
  }
  #contact label {
    font-size: 3vw;
  }
  button.btn {
    font-size: 3.5vw;
    letter-spacing: 2.5px;
  }
}

@media (max-width: 750px) {
  #contact p {
    font-size: 2.5vw;
  }
}

@media (max-width: 650px) {
  #contact p {
    font-size: 3vw;
  }
}

@media (max-width: 500px) {
  #contact svg {
    margin-right: 10px;
  }
}

@media (max-width: 550px) {
  #contact img {
    width: 10vw;
  }
  #contact h5 {
    font-size: 4.5vw;
  }
  #contact label {
    font-size: 4vw;
  }
  button.btn {
    letter-spacing: 3px;
    font-size: 4.5vw;
  }
}

@media (max-width: 450px) {
  #contact p {
    font-size: 3.5vw;
  }
}

@media (max-width: 400px) {
  #contact svg {
    width: 8%;
  }
}

@media (max-width: 291px) {
  #contact p {
    letter-spacing: 1.5px;
  }
}