/* COLORS */
:root {
  --main: #E3FF05;
  --secondary: #f08080;
}

#bottomNav {
  overflow: hidden;
}

#newsSub {
  color: var(--main);
}

#newsSub:hover {
  color: black;
}

#newsDetails {
  font-size: 17px;
  margin-top: 27px;
  color: white;
}

@media only screen and (max-width: 650px) {
  .newsletter input {
    margin-bottom: 5px;
  }
  .newsletter input.form-control {
    max-width: 286px;
  }
}

@media only screen and (max-width: 576px) {
  .newsletter input {
    margin-bottom: 0px;
  }
  input.form-control {
    margin-left: 0px;
  }
}

/*BOTTOM NAV ICONS*/

.iconsNav {
  margin: 0px 9px;
}

.iconsNav img {
  border-radius: 11px;
  width: 40px;
  margin: 11px 7px;
}

.mailz {
  border-radius: 4px;
}

#st-1 span {
  font-family: 'Sancreek';
}

#contact a {
  color: var(--main);
}

.jumbotron p img {
  height: 33px;
}

a {
  cursor: pointer;
  color: white;
}

/*FOOTER */

footer {
  color: white;
  background-color: rgba(0,0,0,0.5);
}

footer h5 {
  color: var(--main);
  text-shadow: 2px 0px black;
}

#footer-links .nav-link {
  padding: 0px;
  width: fit-content;
  margin: auto;
  margin-bottom: 10px;
}

#footer-links li {
  padding: 0px 16px;
  color: white;
}

#footer-links li a:hover {
  color: var(--main);
  text-shadow: 1px 1px black;
}

.footer-copyright {
  color: black;
  border-top: 1px solid black;
}

.footer-copyright a {
  color: black;
  text-shadow: 1px 1px 0px black;
}

.footer-copyright p {
  margin-bottom: 5px;
}

a.nav-item.active {
  color: var(--main);
  text-shadow: 1px 1px black;
}


#subscribe {
  margin: 0px 13px;
}

@media only screen and (max-width: 485px) {
  .iconsNav {
    margin-top: 13px;
  }
  #bottomNav {
    padding-top: 21px;
  }
  .iconsNav img {
    width: 9.5vw;
  }

}

@media only screen and (max-width: 400px) {
  #newsDetails {
    font-size: 4vw;
  }
  ul#newsDetails {
    padding-left: 20px;
  }
  
}

@media only screen and (max-width: 300px) {
  ul#newsDetails {
    padding-left: 10px !important;
  } 
}