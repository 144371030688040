/* COLORS */
:root {
  --main: #E3FF05;
  --secondary: lightcoral;
}

.secondary-nav {
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--main);
  text-shadow: 1px 1px var(--secondary);
  box-shadow: 0px -1px 2px black;
  border-bottom: 0.5px solid white;
  position: sticky;
  z-index: 100;
  top: 88px;
  width: 100%;
}

@media only screen and (max-width: 991px) {
  .secondary-nav {
    top: 87px;
  }
}
