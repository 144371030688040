/* COLORS */
:root {
  --main: #E3FF05;
  --secondary: lightcoral;
}

.accordion {
  max-width: 827px;
  margin: auto;
  margin-top: 50px;
}

.accordion h5 {
  color: var(--main);
  text-align: center;
  font-variant: small-caps;
  font-size: 40px;
}

.accordion .accordion-collapse {
  background-color: rgba(0, 0, 0, 0.9);
}

/* .accordion .accordion-header {
  background: black;
  border-radius: 5px;
} */

.accordion .accordion-header .btn-sm {
  margin-left: 5px;
}

.accordion .accordion-body {
  color: white;
  align-items: center;
  padding: 20px 15px;
}

.accordion .accordion-button {
  background-color: black;
  color: var(--main);
}

.accordion .accordion-button:not(.collapsed) {
  background-color: black;
  color: var(--main);
}

.accordion-button::after {
  display: none;
}

.accordion-button:focus {
  box-shadow: 0 0 0 0.25rem var(--secondary);
}

.accordion .img-thumbnail {
  max-width: 200px;
}

@media (max-width: 320px) {
  .accordion .img-thumbnail {
    width: 62vw;
    max-width: unset;
  }
}

.accordion-img {
  display: flex;
  justify-content: center;
  margin: 1px 0px;
}

.itemInfo {
  text-align: center;
  margin: 15px 0px;
  height: fit-content;
}

.itemInfo .subhead {
  color: var(--main);
  text-shadow: 1px 1px var(--secondary);
  font-size: 30px;
}

.subItemVal {
  padding-left: 7px;
  color: white;
  display: inline-block;
}

.memberMediaIcon {
  color: white;
}

.accordion .content {
  color: var(--main);
  background: rgba(0, 0, 0, 0.4);
  border-radius: 14px;
  padding: 27px 0px;
  margin-top: 7px;
}

.accordion .content .subItemVal .btn {
  margin: 13px 0px;
}

.addButton.btn {
  margin: 27px auto;
  width: 69%;
  min-width: 250px;
}

.bi-plus-square-fill {
  margin-right: 10px;
}

.deleteItem span {
  color: var(--main);
  margin: 0px 5px;
}

.accordion .modify-options {
  position: absolute;
  right: 29px;
  display: flex;
}

.accordion .modify-options a.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 9px 11px;
}

@media (max-width: 777px) {
  .header {
    font-size: 3vw;
  }
  .accordion-header .coHeader {
    font-size: 2.5vw;
  }
  .itemInfo .subhead {
    font-size: 4.5vw;
  }
  .itemInfo .subhead .coSubhead {
    font-size: 4.0vw;
  }
  .content {
    font-size: 3.5vw;
  }
  .subItemVal {
    font-size: 3.5vw;
  }
}

@media (max-width: 450px) {
  .header {
    font-size: 3.5vw;
  }
  .accordion-header .coHeader {
    font-size: 3vw;
    letter-spacing: 1px;
  }
  .itemInfo .subhead {
    font-size: 5vw;
  }
  .itemInfo .subhead .coSubhead {
    font-size: 4.5vw;
  }
  .content {
    font-size: 4.5vw;
  }
  .subItemVal {
    font-size: 4.5vw;
  }
}