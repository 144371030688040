/* FONTS */
@font-face {
  font-family: stencil;
  src: url(fonts/stencil.ttf);
}
@font-face {
  font-family: sprayPaint;
  src: url(fonts/sprayPaint.ttf);
}
/* COLORS */
:root {
  --main: #E3FF05;
  --secondary: rgb(240, 128, 128);
}

html,
body {
  font-family: sprayPaint;
  font-size: 22px;
  background-color: #272727;
  letter-spacing: 6px;
}


/* FADE IN EFFECT */

.pace-running .fadeIn > *:not(.pace) {
  opacity: 0;
}

.pace-done .fadeIn > *:not(.pace) {
  opacity: 1;
  animation: FadeAnim 1.5s ease;
}

/* .fadeIn {
  animation: FadeAnim 3s ease-in;
} */

@keyframes FadeAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*GENERAL*/

.red {
  color: darkred;
}

h5 {
  margin: 16px;
}

@media (max-width: 350px) {
  h5 {
    font-size: 7vw;
  }
}

.nav {
  z-index: 100;
}

.btn-danger {
  background-color: var(--main);
  color: black;
  border-color: white;
}

.btn-danger:hover {
  background-color: black;
  border-color: var(--main);
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #E3FF05;
  color: black;
}

.btn-check:focus+.btn-danger, .btn-danger:focus {
  background-color: #E3FF05;
  color: black;
}

.nav-link {
  color: white;
}

@media (max-width: 2000px) {
  .cart-button {
    font-size: 1.5vw;
  }
}

@media (max-width: 991px) {
  .cart-button {
    font-size: 3vw;
  }
}

@media (max-width: 500px) {
  .cart-button {
    font-size: 7vw;
  }
}


a {
  text-decoration: none;
}

a:hover {
  color: white;
}

p a:hover {
  text-decoration: underline;
}

.form-control {
  border: 2px solid rgba(425, 425, 425, 0.1);
}

.form-control:focus {
  border-color: var(--secondary);
  box-shadow: none;
  -webkit-box-shadow: none;
}

.has-error .form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}

hr {
  background-color: black;
}

.modal-content {
  background-color: rgba(0, 0, 0, 0.4);
  border: 2px solid var(--main);
  color: var(--main);
}

.modal-body {
  color: white;
}

/* HOVER CSS */

#menu .hvr-sink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
#menu .hvr-sink:hover,
.hvr-sink:focus,
.hvr-sink:active {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.addButton.btn {
  margin: 27px auto;
  max-width: 420px;
}

#no_content {
  margin: 20% 20px;
  color: var(--main);
  text-align: center;
}

.at {
  font-family: 'auto';
}

@media (max-width: 991px) {
  p, a {
    font-size: 2vw;
  }
}

@media (max-width: 800px) {
  p, a {
    font-size: 2.5vw;
  }
  #no_content {
    font-size: 5vw;
    margin: 20vh 20px
  }
}

@media (max-width: 800px) {
  p, a {
    font-size: 3vw;
  }
}

@media (max-width: 777px) {
  p, a {
    font-size: 2.5vw;
  }
  h5 {
    font-size: 3.5vw;
  }
  label {
    font-size: 3vw;
  }
  button.btn, .btn {
    font-size: 3.5vw;
    letter-spacing: 2.5px;
  }
}

@media (max-width: 750px) {
  p, a {
    font-size: 2.5vw;
  }
}

@media (max-width: 650px) {
  p, a {
    font-size: 3vw;
  }
}

@media (max-width: 500px) {
  svg {
    margin-right: 10px;
  }
}

@media (max-width: 550px) {
  /* img {
    width: 10vw;
  } */
  h5 {
    font-size: 4.5vw;
  }
  label {
    font-size: 4vw;
  }
  button.btn, .btn {
    letter-spacing: 3px;
    font-size: 4.5vw;
  }
  input.form-control {
    font-size: 4vw;
  }
  svg {
    width: 6vw;
  }
}

@media (max-width: 450px) {
  p, a {
    font-size: 3.5vw;
  }
}

@media (max-width: 400px) {
  button.btn, .btn {
    padding: 4px 8px;
  }
  button.navbar-toggler {
    font-size: 7vw;
  }
}

@media (max-width: 291px) {
  p, a {
    letter-spacing: 1.5px;
  }
}