/* COLORS */
:root {
    --main: #E3FF05;
    --secondary: lightcoral;
  }
  
.offcanvas {
    color: white;
    width: 100%;
}

.offcanvas-header {
    background-color: black;
    color: var(--main);
    text-shadow: 2px 1px var(--secondary);
}

.offcanvas-body {
    background-color: #272727;
}

.productsContainer {
    padding-bottom: 10px;
}

.orderSummary {
    text-align: center;
    padding: 15px;
    background-color: rgba(0,0,0,0.5);
    border-radius: 7px;
}

.orderSummary h5 {
    color: var(--main);
    text-shadow: 2px 1px var(--secondary);
}

.orderDetails {
    padding-bottom: 20px;
}

.orderDetails span {
    color: var(--main);
}

.orderDetails .totalCost {
    font-size: 27px;
    margin-top: 10px;
}


.offcanvas-header button.btn-close:focus:not(:focus-visible) {
    box-shadow: none;
  }


@media (max-width: 750px) {
    .productsContainer h1 {
        font-size: 5vw;
    }
    .orderDetails span, .orderDetails *, .orderDetails .totalCost {
        font-size: 3.5vw;
    }
}


@media (max-width: 500px) {
    .productsContainer h1 {
        font-size: 7vw;
    }
}




